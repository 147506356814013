import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import NavBar from "./NavBar/NavBar";
import HomePage from "./HomePage/HomePage";
import JournalPage from "./JournalPage/JournalPage";
import IssuePage from "./IssuePage/IssuePage";
import ArticlePage from "./ArticlePage/ArticlePage";
import ContactUsPage from "./OtherPages/ContactUsPage";
import FAQPage from"./OtherPages/FAQPage";
import AboutPage from "./OtherPages/AboutPage";
import UploadPage from "./UploadPage/UploadPage";
import "./App.css";

function App() {
    useEffect(() => {
        document.title = "Ungated Research";
    }, []);

    return (
        <>
            <NavBar />
            <div style={{ paddingBottom: "3rem" }}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route
                        path="/journal/:journalId"
                        element={<JournalPage />}
                    />
                    <Route path="/issue/:issueId" element={<IssuePage />} />
                    <Route
                        path="/article/:articleId"
                        element={<ArticlePage />}
                    />
                    <Route path="/contact" element={<ContactUsPage />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route
                        path="/upload-new-article"
                        element={<UploadPage />}
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </div>
        </>
    );
}

export default App;
